import StarIcon from '@mui/icons-material/Star'

function Footer() {
  return (
    <div
      style={{
        position: 'absolute',
        bottom: 24,
        height: 20,
        display: 'flex',
        alignItems: 'center',
        width: 240,
        color: 'grey',
      }}
    >
      <StarIcon />
      <span style={{ fontWeight: 'bold', fontSize: 14 }}>標示為重要</span>

      <div style={{ flex: 1 }} />
      <span style={{ fontWeight: 'bold', fontSize: 14 }}>送出</span>
    </div>
  )
}

export default Footer
