import DeviceFrame from './_components/DeviceFrame'
import Footer from './_components/Footer'
import Header from './_components/Header'
import Report from './_components/Report'
import styles from './index.module.css'

function MobilePreview() {
  return (
    <div className={styles.root}>
      <DeviceFrame />
      <Header />

      <div className={styles.body}>
        <Report />
      </div>

      <Footer />
    </div>
  )
}

export default MobilePreview
