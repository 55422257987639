import { useAtomValue } from 'jotai'
import ReactQuill from 'react-quill-new'
import { reportAtom } from '../../../ReportTemplateEditor'
import useWorkplaceId from 'components/pages/ReportsPage/_components/DailyReportsPage/_hooks/useWorkplaceId'

interface Props {
  workplaceId: string
}

function Report({ workplaceId }: Props) {
  const report = useAtomValue(reportAtom(workplaceId))

  const myColors = [
    'red',
    'orange',
    'yellow',
    'green',
    'blue',
    'cyan',
    'purple',
    'white',
    'black',
  ]

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
      [{ color: myColors }],
      [{ background: myColors }],
    ],
  }

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'image',
    'background',
    'align',
  ]

  return (
    <div style={{ padding: '0 4px' }}>
      {report && (
        <ReactQuill
          readOnly
          formats={formats}
          modules={modules}
          theme="snow"
          value={report}
        />
      )}
    </div>
  )
}

const ReportComponent = () => {
  const workplaceId = useWorkplaceId()
  if (!workplaceId) return null
  return <Report workplaceId={workplaceId} />
}

export default ReportComponent
