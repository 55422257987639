import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import InputAdornment from '@mui/material/InputAdornment'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { setWorkplaceDailyReportAtom } from 'components/pages/ReportsPage/_components/DailyReportsPage/_atoms/WorkplaceDailyReports'
import useWorkplaceId from 'components/pages/ReportsPage/_components/DailyReportsPage/_hooks/useWorkplaceId'
import format from 'date-fns/format'
import { BusinessWorkplaceDailyReport } from 'gql/types'
import getRoundedNumber from 'helpers/getRoundedNumber'
import parseDate from 'helpers/parseDate'
import { useSetAtom } from 'jotai'
import React, { useMemo, useState } from 'react'
import useWorkplaceName from './_hooks/useWorkplaceName'
import styles from './index.module.css'
import Report from './_components/Report'
import Delta from 'quill-delta'
import useQueryDailyReports from 'components/pages/ReportsPage/_components/DailyReportsPage/_hooks/useQueryDailyReports'
import useReportTemplate from 'components/pages/ReportsPage/_components/DailyReportTemplate/_hooks/useReportTemplate'
import StarIcon from '@mui/icons-material/Star'
import { IconButton } from '@mui/material'
import useCustomFields from 'components/pages/ReportsPage/_components/DailyReportTemplate/_hooks/useCustomFields'
import { CustomField } from 'components/pages/ReportsPage/_components/DailyReportTemplate/_components/CustomFields'

interface CustomFieldInputAdornmentProps {
  title: string
  customFieldsTemplate: CustomField[]
}

const getCustomFieldInputAdornment = ({
  title,
  customFieldsTemplate,
}: CustomFieldInputAdornmentProps) => {
  const customFieldTemplate = customFieldsTemplate.find(
    customField => customField.title === title
  )
  if (!customFieldTemplate || customFieldTemplate.type !== 'custom')
    return {
      startAdornment: (
        <InputAdornment position="start">
          <Typography variant="caption">$</Typography>
        </InputAdornment>
      ),
    }

  return {
    endAdornment: (
      <InputAdornment position="end">
        <Typography variant="caption">{customFieldTemplate.unit}</Typography>
      </InputAdornment>
    ),
  }
}

interface Props {
  onClose: () => void
  dailyReport: BusinessWorkplaceDailyReport
}

function DailyReportDialog({ onClose, dailyReport }: Props) {
  const workplaceId = useWorkplaceId()
  const workplaceName = useWorkplaceName()
  const reportTemplate = useReportTemplate()
  const customFieldsTemplate = useCustomFields()
  const queryDailyReports = useQueryDailyReports()
  const setWorkplaceDailyReport = useSetAtom(setWorkplaceDailyReportAtom)

  const [editMode, setEditMode] = useState(false)

  const [starred, setStarred] = useState(dailyReport.starred || false)
  const [revenue, setRevenue] = useState(dailyReport.revenue || 0)
  const [customers, setCustomers] = useState(dailyReport.customers || 0)

  const [modifiedFields, setModifiedFields] = useState<Map<string, number>>(
    new Map()
  )

  const [modifiedReport, setModifiedReport] = useState<Delta | null>(null)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const customFields = useMemo(() => {
    if (!dailyReport.metaJson || dailyReport.metaJson === 'null')
      return Object.fromEntries(
        customFieldsTemplate.map(({ title }) => [title, undefined])
      )

    const metaJson = JSON.parse(dailyReport.metaJson)
    if (!metaJson['customFields']) return []
    return Object.fromEntries(
      customFieldsTemplate.map(({ title }) => [
        title,
        metaJson['customFields'][title],
      ])
    )
  }, [customFieldsTemplate, dailyReport.metaJson])

  const report = useMemo(() => {
    if (!reportTemplate) return null
    if (!dailyReport.metaJson || dailyReport.metaJson === 'null')
      return reportTemplate
    const metaJson = JSON.parse(dailyReport.metaJson)
    if (!metaJson['report']) return reportTemplate
    const delta = metaJson['report']['ops'] || metaJson['report']
    return new Delta(JSON.parse(delta))
  }, [dailyReport.metaJson, reportTemplate])

  const handleEditModeChanged = () => setEditMode(!editMode)

  const handleRevenueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10)
    if (value >= 0) {
      setRevenue(value)
    }
  }

  const handleCustomersChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value, 10)
    if (value >= 0) {
      setCustomers(value)
    }
  }

  const handleCustomFieldChanged =
    (title: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(e.target.value, 10)
      if (value >= 0) {
        const newModifiedFields = new Map(modifiedFields)
        newModifiedFields.set(title, value)
        setModifiedFields(newModifiedFields)
      }
    }

  const handleStarredToggle = () => setStarred(!starred)

  const handleReportChanged = (delta: Delta) => setModifiedReport(delta)

  const handleClose = () => {
    setIsSubmitting(false)
    setRevenue(dailyReport.revenue || 0)
    setCustomers(dailyReport.customers || 0)
    onClose()
  }

  const handleSave = async () => {
    setIsSubmitting(true)

    const newCustomFields = new Map([
      ...new Map(Object.entries(customFields)),
      ...modifiedFields,
    ])

    await setWorkplaceDailyReport({
      dailyReport: {
        date: dailyReport.date,
        workplaceId: workplaceId,
        revenue,
        customers,
        starred,
        metaJson: JSON.stringify({
          customFields: Object.fromEntries(newCustomFields),
          report: JSON.stringify(modifiedReport || report),
        }),
      },
    })

    queryDailyReports()

    handleClose()
  }

  const latestEventlog = dailyReport.eventLogs.slice(-1)[0]

  return (
    <Dialog maxWidth="md" fullWidth onClose={onClose} open>
      <DialogTitle>{`${workplaceName} - ${dailyReport.date} 日報表`}</DialogTitle>
      <DialogContent dividers>
        <div className={styles.root}>
          <div className={styles.left}>
            <Typography className={styles.title}>每日回報項目</Typography>
            <div className={styles.row}>
              <Typography className={styles.label}>總營業額</Typography>
              <TextField
                className={styles.field}
                disabled={!editMode}
                autoComplete="off"
                size="small"
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography variant="caption">$</Typography>
                    </InputAdornment>
                  ),
                }}
                value={revenue}
                onChange={handleRevenueChanged}
              />
            </div>

            <div className={styles.row}>
              <Typography className={styles.label}>來客數</Typography>
              <TextField
                className={styles.field}
                disabled={!editMode}
                autoComplete="off"
                size="small"
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography variant="caption">人</Typography>
                    </InputAdornment>
                  ),
                }}
                value={customers}
                onChange={handleCustomersChanged}
              />
            </div>

            <div className={styles.row}>
              <Typography className={styles.label}>總超時</Typography>
              <TextField
                className={styles.field}
                disabled
                size="small"
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography variant="caption">小時</Typography>
                    </InputAdornment>
                  ),
                }}
                value={getRoundedNumber(dailyReport.overtimeHours || 0)}
              />
            </div>

            <Typography className={styles.title}>門店自訂項目</Typography>
            {Object.entries(customFields).map(([title, amount]: any) => (
              <div className={styles.row}>
                <Typography className={styles.label}>{title}</Typography>
                <TextField
                  disabled={!editMode}
                  className={styles.field}
                  autoComplete="off"
                  size="small"
                  type="number"
                  InputProps={getCustomFieldInputAdornment({
                    title,
                    customFieldsTemplate,
                  })}
                  value={modifiedFields.get(title) || amount}
                  onChange={handleCustomFieldChanged(title)}
                />
              </div>
            ))}
          </div>
          <div className={styles.right}>
            <Typography className={styles.title}>門店日誌</Typography>
            {report && (
              <Report
                readonly={!editMode}
                report={modifiedReport || report}
                onChanged={handleReportChanged}
              />
            )}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <IconButton
          color={starred ? 'primary' : 'default'}
          onClick={handleStarredToggle}
        >
          <StarIcon />
        </IconButton>
        <Typography style={{ color: 'grey', marginRight: 32 }}>
          標示為重要
        </Typography>
        {latestEventlog && (
          <Typography style={{ marginLeft: 8, color: 'grey' }}>
            {`最後編輯：${latestEventlog.employee!.name} 
            ${format(
              parseDate(latestEventlog.insertedAt),
              'yyyy-MM-dd hh:mm:ss'
            )}`}
          </Typography>
        )}
        <div style={{ flex: 1 }} />
        {!editMode && (
          <Button
            color="primary"
            variant="contained"
            onClick={handleEditModeChanged}
          >
            編輯
          </Button>
        )}
        {editMode && (
          <Button
            color="primary"
            variant="outlined"
            onClick={handleEditModeChanged}
          >
            取消
          </Button>
        )}
        {editMode && (
          <Button
            color="primary"
            variant="contained"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress size={14} /> : null}
            onClick={handleSave}
          >
            更新
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default DailyReportDialog
